/**
* Welcome to your ✨𝔲𝔱𝔦𝔩𝔦𝔱𝔶 𝔰𝔱𝔶𝔩𝔢𝔰✨!
* This file includes utility classes that are super simple
* and can be used to add general styles; variable definitions
* for colors; and styles to html, body, and other high level
* DOMs.
*
* All utility classes start with a `u-` and all do
* one basic CSS thing (for example, making the font-weight
* 600 for bolding) or are super generic.
*
* This is 𝙉𝙊𝙏 the place to define classes for components or
* do rigorous styling. You shoud not need to change this file
* much after initial creation.
*/
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
    font-family: "Familjen Grotesk";
    src: url("/public/familjen-grotesk/fonts/otf/FamiljenGroteskGF-Regular.otf")
            format("opentype"),
        url("/public/familjen-grotesk/fonts/ttf/FamiljenGroteskGF-Regular.ttf")
            format("truetype");
    font-weight: normal;
    font-style: normal;
}

:root {
    --primary: #396dff;
    --primary--dim: #6987db;
    --darkgrey: #d4d4d4;
    --medgrey: #e0e0e0;
    --grey: #f7f7f7;
    --white: #fff;

    --xs: 4px;
    --s: 8px;
    --m: 16px;
    --l: 24px;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Familjen Grotesk", "Hiragino Kaku Gothic Pro", "Meiryo",
        sans-serif;
    box-sizing: border-box;
}

.react-datetime-picker input {
    border: 1px solid #ccc;
    padding: 5px;
    /* Add any other desired styles */
}

.downloadButton {
    background: linear-gradient(
        180deg,
        #ff8c05 35.94%,
        rgba(255, 249, 249, 0) 100%
    );
}

.regi_progress_item.now {
    font-weight: bold;
    color: #fff;
    background: #02ceb9;
}

.regi_progress_item:before {
    border-left: 20px solid #fff;
    top: -3px;
    right: -20px;
    z-index: 1;
}

.regi_progress_item:before,
.regi_progress_item:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-bottom: 28px solid transparent;
    border-top: 28px solid transparent;
}

.regi_progress_item.now:after {
    border-left-color: #02ceb9;
}

.regi_progress_item:after {
    border-left: 20px solid #ddd;
    color: #fff;
    top: -3px;
    right: -18px;
    z-index: 1;
}

.regi_progress_item {
    position: relative;
    flex: 1 1 150px;
    padding: 10px;
    color: #999;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-sizing: border-box;
}
.regi_progress_item:not(:first-child) {
    padding-left: 25px;
    padding-right: 0;
}

.verifyInput > div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
}

.verifyInput > div > input {
    width: 40px;
    height: 40px;
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    font-size: larger;
}

@media screen and (max-width: 700px) {
    .regi_progress_item.now {
        flex: 1 1 300px;
    }
    .regi_progress_item {
        flex: 1 3 200px;
        color: #ddd;
    }
}
/*
form {
  flex-grow: 1;
}

.u-flex {
  display: flex;
}

.u-flexColumn {
  display: flex;
  flex-direction: column;
}

.u-flex-justifyCenter {
  justify-content: center;
}

.u-flex-alignCenter {
  align-items: center;
}

.u-inlineBlock {
  display: inline-block;
}

.u-bold {
  font-weight: 600;
}

.u-textCenter {
  text-align: center;
}

.u-relative {
  position: relative;
}

.u-pointer {
  cursor: pointer;
}

.u-link {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.u-link:hover {
  color: var(--primary--dim);
} */

input.autofill-transparent:-webkit-autofill,
input.autofill-transparent:-webkit-autofill:hover,
input.autofill-transparent:-webkit-autofill:focus,
input.autofill-transparent:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.5) inset !important;
    -webkit-text-fill-color: inherit !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard */
}

/* Cusstom MUI */
.no-padding > div > input {
    padding: 0 !important
}

.no-padding > div {
    padding: 0 !important
}

.no-padding > .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0 !important
}

.datetime-custom > input {
    padding: 7px 12px;
    border-radius: 6px;
    width: 100%;
}
.no-border > input:focus {
    outline-width: 0;
}

.no-border > div > fieldset {
    border: none !important
}

.preview-player video{
  border-radius: 10px;
}

.preview-player-audio audio{
  border-radius: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.custom-quilljs a{
  color: #396dff;
  text-decoration: underline;
}

.top-search > div > input {
    color: white;
}

.top-search > div > input::placeholder {
    color: white;
    opacity: 0.8;
    font-weight: 400;
}

.croppie-container .cr-slider-wrap {
  margin-bottom: 0;
}

@media screen and (min-width: 1024px) {
  .parent-hover:hover .child-display{
    display: flex;
  }
}
