.Dashboard {
  background-color: #f2f2f7;
}

.slide-container {
  display: flex;
  justify-content: center;
}

.slide-content {
  margin: 0 auto;
  height: 300px;
}

.selectiveBackground {
  height: 300px;
  width: 100%;
  background-image: url(/public/big-image.jpeg);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  /* opacity: 50%; */
  position: relative;
}

.logo {
  width: 100px;
  height: 100px;
  background-image: url(/public/logo.png);
  background-size: cover;
}

.logo-side {
  width: 180px;
  height: 180px;
  background-image: url(/public/image/logo-sidebar.png);
  background-size: cover;
}

.log {
  width: 30px;
  height: 30px;
  background-image: url(/public/favicon.ico);
  background-size: cover;
}

.mainUserInfo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.userInformation {
  color: white;
}

.userImage {
  height: 200px;
  width: 200px;
  overflow: hidden;
  border-radius: 100px;
}

.userAvatar {
  width: 200px;
  height: 200px;
}

.userName {
  font-weight: 900;
  font-size: x-large;
  margin: 20px;
}

.userEmail {
  font-weight: 600;
}

.mainButtons {
  text-align: center;
}

.searchButton {
  width: 560px;
  display: flex;
  margin: 60px auto 32px;
  background-color: #DADADA;
  align-items: center;
  justify-content: center;
  height: 40px;
}

#searchIcon {
  height: 26px;
  width: 70px;
}

#stick {
  width: 3px;
  height: 30px;
  background-color: white;
}

#deleteIcon {
  height: 22px;
  width: 80px;
}

.searchInput {
  width: 400px;
  background-color: #dadada99;
  border: none;
  margin-left: 7px;
  height: 36px;
  color: white;
  font-size: 16px;
}

.searchInput::placeholder {
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.searchInput:focus {
  outline: none;
  border: none;
}

.onlinePattern {
  width: 560px;
  height: 40px;
}

.snsPattern {
  width: 560px;
  height: 40px;
}

.plusIcon {
  height: 23px;
  width: 80px;
}

.buttonName {
  width: 280px;
  font-weight: 600;
  color: white;
}

.space {
  width: 80px;
}

.contactManage {
  display: flex;
  justify-content: center;
  background-color: #96DA45;
  align-items: center;
  margin: 0 auto 24px;
  position: relative;
}

.degimeWrite {
  display: flex;
  justify-content: center;
  background-color: #FF8F61;
  align-items: center;
  margin: 0 auto 24px;
  position: relative;
}

.degimeBuy {
  display: flex;
  justify-content: center;
  background-color: #DA8D45;
  align-items: center;
  margin: 0 auto;
  position: relative;
}

.button {
  border-radius: 15px;
  height: 50px;
}

.mainButtonMargin {
  margin: 0 auto 32px;
}

.bottom {
  max-width: 960px;
  height: 70px;
  background-color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.small-btn {
  width: 400px;
}

.bottomSpace {
  height: 28px;
  max-width: 960px;
}

.button:hover {
  cursor: pointer;
}

.list {
  margin: 0 auto;
  padding: 10px 0;
  border-bottom: 1px black dotted;
}

.alarmPart {
  position: absolute;
  right: 20px;
  margin-top: 24px;
  display: flex;
}

#alarm {
  height: 20px;
  margin-right: 20px;
}

.alarmBadge {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: yellow;
  position: absolute;
  bottom: 0px;
  right: 20px;
}

svg {
  display: inline;
  vertical-align: baseline;
}

#contactLists {
  position: relative;
  cursor: pointer;
  max-width: unset;
}

.downList {
  background-color: white;
  width: 150px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 10px;
  border: solid 2px grey;
  box-shadow: -2px 5px 5px 1px #c7c7c7;
  position: absolute;
  right: 0;
  box-sizing: unset;
  z-index: 10;
  /* display: none; */
}

.dashBoardBadge {
  font-size: 15px;
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #FF8F61;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;

}

.mainContactBadge {
  left: 235px;
  top: 3px;
}

.chatBadge {
  top: -3px;
  left: 22px;
}

.bottomContactBadge {
  top: -3px;
  left: 15px;
}

.listLink {
  text-decoration: none;
  color: black;
  font-weight: 400;
}

.listLink:hover {
  color: #777;
}

.bottomIcon {
  height: 30px;
}

.bottomLink {
  display: flex;
  text-decoration: none;
  align-items: center;
  border-radius: 10px;
  position: relative;
}

.bottomLink:hover {
  background-color: rgb(193 193 193);
  padding: 5px 10px;
  transition-timing-function: ease-in-out;
  transition: 0.5s;
}

.bottomIconContent {
  padding-left: 5px;
  font-weight: 900;
  /*display: none;*/
  /* transition: 2s; */
}

.bottomLink:hover .bottomIconContent {
  display: block;
  transition-timing-function: ease-in-out;
  transition: right display 2s;
}

.bottomLink:hover .chatBadge {
  top: 2px;
  left: 32px;
}

.bottomLink:hover .bottomContactBadge {
  top: 2px;
  left: 25px;
}






@media screen and (max-width:980px) {
  .alarmPart {
    right: 20px;
    margin-left: 0;
  }

  /* #forBottomContactBadge{
        position: relative;
    } */
}

@media screen and (max-width:800px) {
  .searchInput {
    width: 50vw;
  }

  .searchButton {
    width: calc(50vw + 160px);
  }

  .onlinePattern {
    width: calc(50vw + 160px);
  }

  .snsPattern {
    width: calc(50vw + 160px);
  }

  .small-btn {
    width: 50vw;
  }

  .mainContactBadge {
    left: calc(25vw + 35px);
  }
}

@media screen and (max-width:768px) {
  #deleteIcon {
    width: 40px;
  }

  #searchIcon {
    width: 30px;
  }

  .space {
    width: 35px;
  }

  .plusIcon {
    width: 40px;
  }

  .searchButton {
    width: calc(60vw + 80px);
  }

  .onlinePattern {
    width: calc(60vw + 80px);
  }

  .snsPattern {
    width: calc(60vw + 80px);
  }

  .small-btn {
    width: 60vw;
  }

  .slide-content {
    height: 225px;
  }

  .selectiveBackground {
    height: 225px;
  }

  .mainUserInfo {
    top: 150px;
  }

  .userImage {
    width: 120px;
    height: 120px;
  }

  .userInformation {
    color: black;
  }

  .userAvatar {
    height: 150px;
    width: 150px;
    border-radius: 75px;
    display: inline;
    vertical-align: baseline;
  }

  .mainContactBadge {
    left: calc(30vw + 25px);
  }

  .searchButton {
    margin: 50px auto 16px;
  }

  .mainButtonMargin,
  .contactManage,
  .degimeWrite {
    margin: 0 auto 16px;
  }

  .alarmPart {
    margin-top: 32px;
  }
}

@media screen and (max-width:375px) {
  .buttonName {
    font-size: 4vw;
  }

  .button {
    height: 40px;
  }

  .bottom {
    height: 70px;
    /* border-radius: 10px; */
  }

  input::placeholder {
    font-size: 4vw;
    font-weight: 200;
  }
}