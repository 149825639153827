.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
}

.swiper-pagination-bullet {
    width: 30px !important;
    height: 3px !important;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 0.7 !important;
    border-radius: 0px !important;
    background: white !important;
}

.swiper-pagination-bullet-active {
    opacity: 1 !important;
    background: white !important;
}

        
.swiper-button-prev,
.swiper-button-next{
    color: white ;
}



.custom-swiper {
    --swiper-navigation-color: white;
    --swiper-pagination-color: white;
    --swiper-navigation-sides-offset: 150px;
    --swiper-navigation-size: 28px;
}

/* Mobile styles */
@media (max-width: 480px) {
    .custom-swiper {
        --swiper-navigation-color: #000;
        /* Example: change color for mobile */
        --swiper-pagination-color: #000;
        /* Example: change color for mobile */
        --swiper-navigation-sides-offset: 20px;
        /* Reduce offset for mobile */
        --swiper-navigation-size: 20px;
        /* Reduce size for mobile */
    }
}

